/**
 * Habilitar os modais
 */
var $iziModal = $(".iziModal"),
	$iziContato = $(".iziContato"),
	$blur = $('.blur');
if (!$iziModal.length == 0) {
	$iziModal.iziModal({
		width: 750,
		radius: 0,
		borderBottom: false
	});
	$iziContato.on('click', 'a', function(event) {
		event.preventDefault();
		$('#contato').iziModal('open');
	});
}
$(document).on('opening', '.iziModal', function (e) {
	$blur.css('filter', 'blur(6px)');
});
$(document).on('closed', '.iziModal', function (e) {
	$blur.css('filter', 'blur(0)');
});