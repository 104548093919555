var $window = $(window),
	$menu = $('.menu'),
	$menuLinks = $('.menu a:not([data-toggle*="dropdown"])'),
	$menuHamburger = $('#menuHamburger');

$menuHamburger.on('click', function(event) {
	event.preventDefault();
	$(this).toggleClass('is-active');
	$menu.toggleClass('active');
});
$menuLinks.on('click', function(event) {
	$menu.toggleClass('active');
	setTimeout(function() {
		$menuHamburger.removeClass('is-active');
	}, 500);
});
$window.scroll(function(event) {
	if ($window.scrollTop() >= 5) $menu.addClass('is-active');
	else $menu.removeClass('is-active');
});
$menu.find('li.dropdown').hover(function() {
	$(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(500);
}, function() {
	$(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
});

$window.trigger('scroll');