// Sliders
var $slick = $('#slick');
$slick.slick({
	arrows: true,
	dots: false,
	pauseOnHover: true,
	autoplay: true,
	cssEase: 'ease-in-out',
	fade:true,
	speed: 2000,
	autoplaySpeed: 2000,
	prevArrow: '<button type="button" class="slick-arrows slick-prev"></button>',
	nextArrow: '<button type="button" class="slick-arrows slick-next"></button>'
});

// Pre-posts
var $slickCursos = $('.pre-posts-slider');
// if ($(window).width() > 1050) {
// 	var $slickCursosWidth = $slickCursos.width() - 160;
// 	$slickCursos.find('[data-width]').each(function(index) {
// 		var $this = $(this),
// 			width = $slickCursosWidth / $this.data('width');
// 		$this.css('width', width+'px');
// 	});
// }
$slickCursos.slick({
	arrows: true,
	dots: false,
	autoplay: true,
	speed: 2000,
	slidesToShow: 3,
	pauseOnHover: true,
	prevArrow: '<button type="button" class="slick-arrows slick-prev"></button>',
	nextArrow: '<button type="button" class="slick-arrows slick-next"></button>',
	responsive: [
		{
			breakpoint: 1050,
			settings: {
				slidesToShow: 2,
				autoplay: false
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				autoplay: false
			}
		}
	]
});

// Depoimentos
var $slickDepoimentos = $('#slick-depoimentos');
$slickDepoimentos.slick({
	arrows: true,
	dots: false,
	speed: 1000,
	slidesToShow: 1,
	slidesToScroll: 1,
	pauseOnHover: true,
	prevArrow: '<button type="button" class="slick-arrows slick-prev"></button>',
	nextArrow: '<button type="button" class="slick-arrows slick-next"></button>',
	responsive: [
		{
			breakpoint: 1025,
			settings: {
				autoplay: false
			}
		}
	]
});

// Clientes
$('#carousel-clientes').slick({
	arrows: true,
	dots: false,
	autoplay: true,
	speed: 1000,
	slidesToShow: 5,
	slidesToScroll: 1,
	infinite: true,
	pauseOnHover: true,
	lazyLoad: 'ondemand',
	prevArrow: '<button type="button" class="slick-arrows slick-prev"></button>',
	nextArrow: '<button type="button" class="slick-arrows slick-next"></button>',
	responsive: [
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 4
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 2,
			}
		}
	]
});

// Depoimentos
var $slickPrePostsHome = $('#slick-pre-posts-home');
$slickPrePostsHome.slick({
	arrows: true,
	dots: false,
	autoplay: true,
	speed: 2000,
	autoplaySpeed: 2000,
	slidesToShow: 2,
	slidesToScroll: 1,
	pauseOnHover: true,
	prevArrow: '<button type="button" class="slick-arrows slick-prev"></button>',
	nextArrow: '<button type="button" class="slick-arrows slick-next"></button>',
	responsive: [
		{
			breakpoint: 1025,
			settings: {
				autoplay: false
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				autoplay: false
			}
		}
	]
});