var $forms = $('.forms');
$forms.each(function() {
  $(this).validate({
    submitHandler: function(form, event) {
      event.preventDefault();
      var $this = $(form),
      $waiting =  $this.find('.waiting'),
      $success =  $this.find('.success'),
      $error =  $this.find('.error'),
      $pdf = $this.find('.pdf-paleta'),
      $response = $this.find('#response');
      $response.css('display', 'block');
      $waiting.css('display', 'block');
      $this.ajaxSubmit({
        url: 'https://www.masterleader.com.br/wp-content/themes/mad-theme/inc/mail/mail.php',
        type: 'POST',
        success: function(response) {
          console.log(response);
          $this[0].reset();
          $waiting.css('display', 'none');
          $success.css('display', 'block');
          setTimeout(function(){
          	if ($pdf.length > 0) {
          		location.href = $pdf.val()
          	}
          dataLayer.push({
            event: 'conversaoAdwords'
          });
	        $success.css('display', 'none');
	        $error.css('display', 'none');
	        $response.css('display', 'none');
	        // location.assign('');
	      }, 5000);
        },
        error: function(response) {
          console.log(response);
          $success.css('display', 'none');
          $waiting.css('display', 'none');
          $error.css('display', 'block');
        }
      });
     }
  });
});
$.extend($.validator.messages, {
    required: "Campo obrigatório.",
    email: "E-mail inválido.",
    tel: "Telefone inválido."
});